export class Barcoder {
  constructor () {
    this.toInt = function (numString) { return parseInt(numString, 10) }
    this.validationRegex = /^\d{8,14}$/
    this.isOdd = function(num) { return (num % 2) === 1 }
    this.calculateChecksum = function(gtin) {
      gtin = parseInt(gtin, 10).toString()
      const chunks = gtin.split('').map(this.toInt).reverse()
      let checksum = 0

      // Remove first chuck (checksum)
      chunks.shift()

      // sum numbers and multiply accordingly
      chunks.forEach((number, index) => { checksum += this.isOdd(index) ? number : number * 3 })

      // calc checksum
      checksum %= 10
      checksum = (checksum === 0) ? 0 : (10 - checksum)

      return checksum
    }
  }

  validate (barcode) {
    // if (this.validationRegex.exec(parseInt(barcode).toString() ) === null) {
    if (this.validationRegex.exec(barcode) === null) {
      return false
    }

    const checksum = parseInt(barcode.substring(barcode.length - 1), 10);
    const calcChecksum = this.calculateChecksum(barcode);

    return ( checksum === calcChecksum );
  }
}

