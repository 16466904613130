import { Controller } from "stimulus"
import Sortable from 'sortablejs'
import { patch } from "@rails/request.js"

export default class extends Controller {
  static targets = ["sortable"]

  connect () {
    this.sortable = Sortable.create(this.sortableTarget, {
      animation: 150,
      onEnd: this.updatePosition.bind(this)
    })
  }

  async updatePosition(event) {
    const response = await patch(this.sortableTarget.dataset.url, {
      body: JSON.stringify({
        sgid: event.item.dataset.sgid,
        position: event.newIndex + 1
      })
    })
  }
}