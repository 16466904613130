// this controlller must be called unique at app level
// it uses a ClassWatcher to fire events when right sidebar is opened or closed
// it calls global window.App.audits_controller to do the AJAX task
import { Controller } from "stimulus"
import { ClassWatcher } from "libraries/class-watcher"

export default class extends Controller {
  connect () {
    new ClassWatcher(
      document.getElementById("audit-sidebar"),
      "c-sidebar-show",
      this.lastAudits,
      this.lastAudits
    )
  }

  lastAudits () {
    if (this.righ_panel_showing) {
      this.righ_panel_showing = false
      window._offset = 0
      $("#last_audits_parent").empty()
      return
    }

    if(window._offset == undefined) {
      window._offset = 0
    }

    window.App.audits_controller.loadMoreAudits()
    this.righ_panel_showing = !this.righ_panel_showing
  }
}