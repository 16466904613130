import 'ol/ol.css'
import {Map, View} from 'ol'
import TileLayer from 'ol/layer/Tile'
import VectorLayer from 'ol/layer/Vector'
import VectorSource from 'ol/source/Vector'
import Feature from 'ol/Feature'
import Point from 'ol/geom/Point'
import OSM from 'ol/source/OSM'
import {Fill, Circle, Style} from 'ol/style'
import {fromLonLat} from 'ol/proj'

export default class TasksShowMap extends ApplicationController {
  static targets = ["map"]
  static values = { lat: Number, lon: Number }

  connect () {
    this.displayMap()
    // Workaround for displaying map. Map is only rendered if page is resized,
    // so force resizing to display map
    const waitForMap = setInterval(
      () => {
        if (document.querySelectorAll('.ol-viewport').length > 0) {
          window.dispatchEvent(new Event('resize'))
          clearInterval(waitForMap)
        }
      },
      200
    )
  }

  displayMap () {
    const vectorLayer = new VectorLayer({
      source: new VectorSource({
        features: [
          new Feature({
            geometry: new Point(this.getCoords())
          })
        ]
      }),
      style: new Style({
        image: new Circle({
          radius: 6,
          fill: new Fill({color: 'red'})
        })
      })
    });

    const map = new Map({
      target: 'map',
      layers: [
        new TileLayer({
          source: new OSM()
        }),
        vectorLayer
      ],
      view: new View({
        center: this.getCoords(),
        zoom: 16
      })
    })
  }

  getCoords () {
    return fromLonLat([this.lonValue, this.latValue])
  }
}