import { DatatableInit } from "./init"
// import { DEFAULT_OPTIONS } from "./init"
// import { DEFAULT_COLUMNDEFS } from "./init"

export class CompaniesDatatable extends DatatableInit {
  // customize datatables for companies dataset
  constructor () {
    // let options = Object.assign({}, DEFAULT_OPTIONS)
    let columndefs = [
      {
        targets: [-1, -2, 5],
        orderable: false,
        searchable: false,
        className: "text-center"
      },
      {
        targets: [4],
        className: "text-center"
      }
    ]
    super("companies_table", columndefs)
  }
}

