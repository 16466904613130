import { DatatableInit } from "./init"
import { DEFAULT_OPTIONS } from "./init"
// import { DEFAULT_COLUMNDEFS } from "./init"

export class UsersDatatable extends DatatableInit {
  // customize datatables for users dataset
  constructor () {

    let columndefs = [
      {
        targets: [6,7],
        className: "text-center"
      },
      {
        targets: [0,-1, -2],
        orderable: false,
        searchable: false,
        className: "text-center"
      },
      {
        targets: [-3],
        searchable: false
      }
    ]
    let options = Object.assign({}, DEFAULT_OPTIONS)
    options.order = [[1, "asc"]]
    super("users_table", columndefs, options)
  }
}

