// called from new.js.erb and edit.js.erb
export default class extends ApplicationController {
  renderForm (formHtml, formId, focusId) {
    App.common_controller.removeBackdrops()

    document.getElementById("remote_container").innerHTML = formHtml
    const form = document.getElementById(formId)
    if (form == undefined) return

    form.addEventListener("shown.coreui.modal", () => {
      const focus = document.getElementById(focusId)
      if (focus) document.getElementById(focusId).focus()
      $("form[data-client-side-validations]").enableClientSideValidations()
    })

    form.addEventListener("keypress", (event) => {
      const keycode = (event.keyCode ? event.keyCode : event.which);
      if (keycode == '13') {
        event.target.blur()
        if ($(".is-invalid").length == 0) {
          $(".validable_form_submit").click()
        } else {
          event.preventDefault()
          event.target.focus()
        }
      }
    })
    new coreui.Modal(form).show()
  }
}