import { Controller } from "stimulus"
import Dropzone from "dropzone"
import "dropzone/dist/min/dropzone.min.css"
import "dropzone/dist/min/basic.min.css"
import { DirectUpload } from "@rails/activestorage"

export default class extends Controller {
  static targets = ["input", "droparea"]

  connect () {
    Dropzone.autoDiscover = false
    this.inputTarget.disable = true
    this.inputTarget.style.display = "none"
    const dropzone = new Dropzone(this.dropareaTarget, {
      url: "/",
      maxFiles: this.maxFiles,
      maxFilesize: "10",
      autoQueue: false,
      acceptedFiles: "image/*",
      dictDefaultMessage: I18n.t("frontend.dropzone.dictDefaultMessage"),
      dictFileTooBig: I18n.t("frontend.dropzone.dictFileTooBig"),
      dictInvalidFileType: I18n.t("frontend.dropzone.dictInvalidFileType"),
      dictResponseError: I18n.t("frontend.dropzone.dictResponseError"),
      dictMaxFilesExceeded: I18n.t("frontend.dropzone.dictMaxFilesExceeded"),
    })
    if (this.imageCount >= this.maxFiles) {
      this.dropareaTarget.style.display = "none"
    }

    dropzone.on("addedfile", file => {
      setTimeout(() => {
        if (file.accepted) {
          const upload = new DirectUpload(file, this.url)
          upload.create((error, blob) => {
            this.hiddenInput = document.createElement("input")
            this.hiddenInput.type = "hidden"
            this.hiddenInput.name = this.inputTarget.name
            this.hiddenInput.value = blob.signed_id
            this.inputTarget.parentNode.insertBefore(this.hiddenInput, this.inputTarget.nextSibling)
            dropzone.emit("success", file)
            dropzone.emit("complete", file)
          })
        } else {
          document.querySelectorAll(".dz-error-message").forEach((e) => { e.style.color = "white" })
        }
      }, 500)
    })
  }

  get url () {
    return this.inputTarget.getAttribute("data-direct-upload-url")
  }

  get maxFiles () {
    return parseInt(this.dropareaTarget.getAttribute("data-max-images"), 10) || 3
  }

  get imageCount () {
    return parseInt(this.dropareaTarget.getAttribute("data-imagecount"), 10) || 0
  }
}