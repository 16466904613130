// this controller manages loading of audits.
// it's called by "load more audits" button and by right-sidebar controller
import { Controller } from "stimulus"
import { activateTooltips, activatePopovers } from "libraries/data-toggles"
import Rails from "@rails/ujs"

export default class extends Controller {
  connect () {
  }

  loadMoreAudits () {
    Rails.ajax({
      url: $("#last_audits_list").data("url")+`?offset=${_offset}`,
      type: "get",
      dataType: "json",
      error: (_jqXHR, textStatus, errorThrown) => App.common_controller.toast(`AJAX Error: ${textStatus}`, errorThrown || "Lost connection to server", "error"),
      success: (data, _textStatus, _jqXHR) => {
        window._offset += data.audits.constants.limit // default 10
        var result = "<div>"

        if (data.audits.data.length === 0) {
          result += `
           <div id="no-activity" class='list-group-item list-group-item-accent-danger list-group-item-divider'>
           ${data.audits.constants.no_actitiy}
           </div>
        `
        } else {
          data.audits.data.forEach(audit => {
            // TODO: better display of audited_changes
            const changes = App.common_controller.syntaxHighlight(audit.audited_changes).replace(/"/g, "&quot;")
            result +=
            `
            <div class='list-group-item list-group-item-accent-${audit.action_class} list-group-item-divider'>
            <div class='d-block'>${audit.auditable_full_name}</div>
            <small class='text-muted'>${audit.action}</small>
            <p class='mb-0'>
            <button type="button" class="btn btn-secondary btn-sm"
              data-toggle="popover"
              title="${window.I18n.t("frontend.common.summary")}"
              data-html="true"
              data-content="<pre>${changes}</pre>"
              data-placement="left"
              data-trigger="focus"
              >${window.I18n.t("frontend.common.summary")}</button>
            <small class='text-muted float-right' data-toggle='c-tooltip' data-placement='top' title='${audit.when}'>
             ${audit.time_ago}</small>
            </p>
            </div>
           `
          })
        }
        result += "</div>"
        if (window._offset === data.audits.constants.limit) {
          $("#last_audits_parent").html(result)
        } else {
          $("#last_audits_parent").append(result)
        }

        if (data.audits.data.length >= data.audits.constants.limit) {
          $("#loadMoreBtn").show()
        } else {
          $("#loadMoreBtn").hide()
        }
        activatePopovers()
        activateTooltips()
      }
    })
  }
}