// rails and stimulus runtimes
require("@rails/ujs").start()
require("turbolinks").start()
import "controllers"
import "libraries"
import "framework"
import "channels"
import "@coreui/icons/css/all.min.css"
import "application.scss"

// PWA
if (navigator.serviceWorker) {
  navigator.serviceWorker.register('/service-worker.js', { scope: './' })
                         .then(function(registration) {
                          //  console.log('[Companion]', 'Service worker registered!')
                          //  console.log(registration)
                         })
}
